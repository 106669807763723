import { render, staticRenderFns } from "./W9List.vue?vue&type=template&id=2347999e&scoped=true&"
import script from "./W9List.vue?vue&type=script&lang=ts&"
export * from "./W9List.vue?vue&type=script&lang=ts&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "2347999e",
  null
  
)

export default component.exports